import React, { FC } from 'react';

import { ContentfulCard } from '@/components/card/Card';
import { Sections } from '@/components/sections/Section';
import { ButtonsSection } from '@/components/sections/buttons/Buttons';
import { CardsSection } from '@/components/sections/cards/Cards';
import { DividerSection } from '@/components/sections/divider/Divider';
import { GallerySection } from '@/components/sections/gallery/Gallery';
import { HeadlineSection } from '@/components/sections/headline/Headline';
import { HeroSection } from '@/components/sections/hero/Hero';
import { LinksSection } from '@/components/sections/links/Links';
import { LogoSection } from '@/components/sections/logo/Logo';
import { ContentfulSection } from '../types.d';

export interface GroupSection extends ContentfulSection {
  sections: [
    | ContentfulCard
    | ButtonsSection
    | CardsSection
    | DividerSection
    | GallerySection
    | HeadlineSection
    | HeroSection
    | LinksSection
    | LogoSection,
  ];
}

/**
 * Group section
 */
const Group: FC<GroupSection> = ({ sections = [] }) => (
  <Sections ignoreContainer sections={sections} />
);

export default Group;
